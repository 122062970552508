import { Fragment } from 'react';
import styled from 'styled-components';
import type { ModalLayoutProps } from './modal-layout';
import ModalLayout from './modal-layout';
import CloseIcon from '../images/close-icon.inline.svg';

export interface ConfirmModalLayoutProps extends ModalLayoutProps {
  icon?: JSX.Element;
  actions?: JSX.Element[];
}

const StyledModalLayout = styled(ModalLayout)`
  display: flex;
  flex-direction: column;

  .close {
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: auto;

    svg {
      color: var(--textColor);
      width: 1rem;
      height: 1rem;
    }
  }

  .icon {
    margin-right: auto;

    svg {
      width: 4rem;
      height: 4rem;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 4rem 0;
  }

  .content {
    flex-grow: 1;
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  /** 768px - tablet **/
  @media (min-width: 48rem) {
    .header {
      flex-direction: row-reverse;
      align-items: center;
      gap: 0;
    }
    .actions {
      flex-direction: row-reverse;
      > * {
        flex: 1 1 0%;
        min-width: min-content;
        white-space: nowrap;
      }
    }
  }
`;

/**
 * This layout extends `ModalLayout` with a few extra features
 * and styles useful for building confirmation modals.
 */
const ConfirmModalLayout = ({
  children,
  icon,
  actions,
  disableDismiss,
  ...props
}: ConfirmModalLayoutProps) => {
  return (
    <StyledModalLayout disableDismiss={disableDismiss} {...props}>
      <>
        <div className="header mb-24">
          {!disableDismiss ? (
            <button
              type="button"
              aria-label="Dismiss"
              className="close"
              onClick={props.onDismiss}
            >
              <CloseIcon aria-hidden="true" />
            </button>
          ) : null}
          {icon ? <div className="icon">{icon}</div> : null}
        </div>

        <div className="content mb-48">{children}</div>

        <div className="actions">
          {actions
            ? actions.map((action, i) => <Fragment key={i}>{action}</Fragment>)
            : null}
        </div>
      </>
    </StyledModalLayout>
  );
};

export default ConfirmModalLayout;
