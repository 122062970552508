import { classList } from '@findigs/helpers/src/helpers/class-list';
import { polymorphic } from '@findigs/helpers/src/helpers/polymorphic';
import type { PolymorphicHTMLProps } from '@findigs/helpers/src/helpers/polymorphic';
import { styles } from './button.module.scss';

export const BUTTON_VARIANT = [
  'FilledBrand',
  'FilledStandard',
  'Outlined',
  'Tonal',
  'Text',
] as const;

export type ButtonVariant = (typeof BUTTON_VARIANT)[number];

export type ButtonProps = PolymorphicHTMLProps & {
  variant: ButtonVariant;

  children?: string | JSX.Element | JSX.Element[];

  /** @default false */
  dark?: boolean;
};

/**
 * Extends the vanilla `button` element with our styling.
 * All vanilla `button` props are forwarded.
 *
 * This component is [polymorphic](/docs/component-system-polymorphic-components--docs).
 */
export const Button = polymorphic(
  { element: 'button', forwardRef: true },
  (
    { variant, dark = false, className, children, ...props }: ButtonProps,
    Component,
    ref
  ) => {
    const classes = classList(styles, variant, { dark }, className);
    return (
      <Component className={classes} {...props} ref={ref}>
        {typeof children === 'string' && variant === 'Text' ? (
          <span>{children}</span>
        ) : (
          children
        )}
      </Component>
    );
  }
);
