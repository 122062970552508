import { brandPrimarySand } from '@findigs/ui-kit/src/css/colors/_colors.module.scss';
import { overlaysDark75 } from '@findigs/ui-kit/src/css/colors/_colors.module.scss';
import { FocusScope } from '@react-aria/focus';
import { usePreventScroll } from '@react-aria/overlays';
import React, { useCallback, useId } from 'react';
import styled from 'styled-components';
import { useModalCounter } from '../hooks/use-modal-counter';

export interface ModalLayoutProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onDismiss: () => void;
  disableDismiss?: boolean;
}

const Backdrop = styled.div`
  position: fixed;
  will-change: transform;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(33, 37, 41, 0);
  z-index: 101;

  display: flex;
  flex-direction: column;

  .dialog {
    width: 100%;
    flex-grow: 1;

    background: ${brandPrimarySand};
    padding: 2.5rem 1.5rem 3rem 1.5rem;
    border-radius: 1rem;
  }

  /** 768px - tablet **/
  @media (min-width: 48rem) {
    background: ${overlaysDark75};
    justify-content: center;
    align-items: center;

    .dialog {
      padding: 2.5rem;
      flex-grow: unset;
    }
  }
`;

/**
 * The most basic Modal layout. Should be used (directly, or indirectly
 * through more advanced Modal layouts) inside a modal to get basic
 * styling and functionality.
 */
const ModalLayout = ({
  children,
  onDismiss,
  style,
  className,
  disableDismiss,
}: ModalLayoutProps) => {
  const titleId = useId();

  usePreventScroll();
  useModalCounter();

  const onKeyUp = useCallback(
    ({ code }: React.KeyboardEvent) => {
      if (code === 'Escape') {
        onDismiss();
      }
    },
    [onDismiss]
  );

  return (
    <>
      <Backdrop>
        <FocusScope contain restoreFocus autoFocus>
          <div
            role="dialog"
            aria-modal="true"
            aria-labelledby={titleId}
            onKeyUp={disableDismiss ? undefined : onKeyUp}
            style={style}
            className={'dialog ' + (className ?? '')}
          >
            {children}
          </div>
        </FocusScope>
      </Backdrop>
    </>
  );
};

export default ModalLayout;
