// extracted by mini-css-extract-plugin
export var blackDefault = "#212529";
export var blackHover = "#2b3036";
export var blackPressed = "#191c1f";
export var blueDefault = "#2960f2";
export var blueHover = "#3a6df3";
export var blueLighter = "#ebf6fe";
export var bluePressed = "#1450f0";
export var brandPrimaryForest = "#212b14";
export var brandPrimaryKeyGreen = "#8ede65";
export var brandPrimarySand = "#fdfbf7";
export var brandSecondaryBerry = "#f3b3f1";
export var brandSecondaryCoral = "#ffa27a";
export var brandSecondaryJade = "#22c386";
export var brandSecondaryPebble = "#ddc8c9";
export var brandSecondarySky = "#add2ff";
export var brandSecondarySun = "#ffce59";
export var grayDark = "#adb5bd";
export var grayDark2 = "#868e96";
export var grayDarker = "#66707a";
export var grayDefault = "#f1f3f5";
export var grayHover = "#dee2e6";
export var grayPressed = "#ced4da";
export var greenDefault = "#1c6c4d";
export var greenLighter = "#d1f3e6";
export var orangeDefault = "#c14b06";
export var orangeLighter = "#fef5f0";
export var overlaysDark16 = "rgba(51, 51, 49, 0.16)";
export var overlaysDark24 = "rgba(51, 51, 49, 0.24)";
export var overlaysDark75 = "rgba(33, 43, 20, 0.75)";
export var overlaysDark8 = "rgba(51, 51, 49, 0.08)";
export var overlaysLight16 = "rgba(253, 251, 247, 0.16)";
export var overlaysLight24 = "rgba(253, 251, 247, 0.24)";
export var overlaysLight75 = "rgba(253, 251, 247, 0.75)";
export var overlaysLight8 = "rgba(253, 251, 247, 0.08)";
export var redDefault = "#de071c";
export var redLight = "#fbdcdd";
export var redLighter = "#fef0f0";
export var tintsBerry10 = "#ffe8fd";
export var tintsBerry100 = "#c078be";
export var tintsBerry20 = "#fdddfb";
export var tintsBerry30 = "#fdd5fb";
export var tintsBerry40 = "#fccdfa";
export var tintsBerry50 = "#f5c2f3";
export var tintsBerry60 = "#f3b3f1";
export var tintsBerry70 = "#eca4e9";
export var tintsBerry80 = "#de92db";
export var tintsBerry90 = "#d082cd";
export var tintsCoral10 = "#ffe4da";
export var tintsCoral100 = "#d6612f";
export var tintsCoral20 = "#ffdbcc";
export var tintsCoral30 = "#ffd1be";
export var tintsCoral40 = "#ffc6ae";
export var tintsCoral50 = "#ffb798";
export var tintsCoral60 = "#ffa27a";
export var tintsCoral70 = "#f98d5f";
export var tintsCoral80 = "#f17e4d";
export var tintsCoral90 = "#e46d3a";
export var tintsJade10 = "#adf5da";
export var tintsJade100 = "#1c8c62";
export var tintsJade20 = "#98f3d1";
export var tintsJade30 = "#6fe8ba";
export var tintsJade40 = "#56dba9";
export var tintsJade50 = "#3ad198";
export var tintsJade60 = "#22c386";
export var tintsJade70 = "#24b57e";
export var tintsJade80 = "#22a775";
export var tintsJade90 = "#1f996b";
export var tintsKeyGreen10 = "#dbffc9";
export var tintsKeyGreen100 = "#52982c";
export var tintsKeyGreen20 = "#cffdb8";
export var tintsKeyGreen30 = "#c1faa4";
export var tintsKeyGreen40 = "#aff58b";
export var tintsKeyGreen50 = "#9eec76";
export var tintsKeyGreen60 = "#8ede65";
export var tintsKeyGreen70 = "#7fcd56";
export var tintsKeyGreen80 = "#72bc4a";
export var tintsKeyGreen90 = "#60aa38";
export var tintsNeutral10 = "#f5f4ee";
export var tintsNeutral100 = "#333331";
export var tintsNeutral20 = "#ebe9e4";
export var tintsNeutral30 = "#dbdad5";
export var tintsNeutral40 = "#cccbc6";
export var tintsNeutral50 = "#b2b1ad";
export var tintsNeutral60 = "#999894";
export var tintsNeutral70 = "#807f7c";
export var tintsNeutral80 = "#666563";
export var tintsNeutral90 = "#4d4c4a";
export var tintsPebble10 = "#fcf0f0";
export var tintsPebble100 = "#b9a6a7";
export var tintsPebble20 = "#f6e8e8";
export var tintsPebble30 = "#f0dfdf";
export var tintsPebble40 = "#ecd9d9";
export var tintsPebble50 = "#e5d0d0";
export var tintsPebble60 = "#ddc8c9";
export var tintsPebble70 = "#d4bfc0";
export var tintsPebble80 = "#ccb6b7";
export var tintsPebble90 = "#c2aeaf";
export var tintsSky10 = "#ebf4ff";
export var tintsSky100 = "#709bd1";
export var tintsSky20 = "#e2efff";
export var tintsSky30 = "#d7e8ff";
export var tintsSky40 = "#c7e0ff";
export var tintsSky50 = "#bbd9ff";
export var tintsSky60 = "#add2ff";
export var tintsSky70 = "#9cc4f5";
export var tintsSky80 = "#8bb7ed";
export var tintsSky90 = "#7daae1";
export var tintsSun10 = "#fff0ce";
export var tintsSun100 = "#d29e21";
export var tintsSun20 = "#ffeaba";
export var tintsSun30 = "#ffe5a9";
export var tintsSun40 = "#ffde93";
export var tintsSun50 = "#ffd677";
export var tintsSun60 = "#ffce59";
export var tintsSun70 = "#f2c047";
export var tintsSun80 = "#e7b236";
export var tintsSun90 = "#dea82b";
export var utilityError10 = "#ffede8";
export var utilityError60 = "#c13e06";
export var utilityInfo10 = "#ebf4ff";
export var utilityInfo60 = "#2a86db";
export var utilitySuccess10 = "#e3fed5";
export var utilitySuccess60 = "#2d7e20";
export var utilityWarning10 = "#fff1d1";
export var utilityWarning60 = "#e3640d";
export var whiteDefault = "#fff";
export var whiteHover = "#f5f5f5";
export var whitePressed = "#e6e6e6";
export var yellowDefault = "#ffcc16";