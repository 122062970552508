/**
 * Object values will be evaluated to `true` or `false`.
 * Keys whose values evaluate to `true` will be included in the
 * resulting className.
 */
export type ClassListObjectArgument = {
  [key in string]: unknown;
};

export type ClassListArgument =
  | string
  | ClassListObjectArgument
  | false
  | null
  | undefined;

/**
 * Helper function to programatically aggregate a list of class names
 * into a single string
 *
 * Objects will have their values evaluated to `true` or `false`.
 * Object keys whose values evaluate to `true` will be included in the
 * resulting className.
 *
 * @returns a string containing the resulting className
 */
export function classList(...args: ClassListArgument[]) {
  const r: string[] = [];
  for (const arg of args) {
    if (arg) {
      if (typeof arg === 'object') {
        let key: keyof ClassListObjectArgument;
        for (key in arg) {
          if (arg[key]) {
            r.push(key);
          }
        }
      } else {
        r.push(arg);
      }
    }
  }
  return r.join(' ');
}
