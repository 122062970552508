/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * We should avoid using `any` for the most part, as there are better alternatives for almost
 * all scenarios
 *
 * That said, since this is a file with complex types and inference requirements, refactoring it
 * to reduce `any` usage is not a trivial task and thus should be treated as a future enhancement
 *
 * If you're the one doing such refactor, make sure you manually test this extensively to ensure
 * inference still works fine afterwards.
 */

/**
 * some of the types within this file were based on styled-components'
 * https://github.com/styled-components/styled-components/blob/944f85018db9745af7ec260558dd5faafae4f811/packages/styled-components/src/types.ts
 */

import type React from 'react';
import { forwardRef } from 'react';

export type PolymorphicHTMLProps<T = any> = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<T>, T>,
  'as' | 'ref'
>;

export type AnyComponent<P = any> =
  | React.ExoticComponent<P>
  | React.ComponentType<P>;

export type ElementOrComponent = React.ElementType | AnyComponent;

export type KnownTarget =
  | React.ElementType //string would accept custom element names, but we don't need that
  | keyof JSX.IntrinsicElements
  | AnyComponent;

/**
 * Used by PolymorphicComponent to define prop override cascading order.
 */
export type PolymorphicComponentProps<
  E extends KnownTarget,
  P extends object,
  ForwardRef extends boolean = false
> = Omit<
  E extends KnownTarget
    ? P &
        Omit<
          ForwardRef extends true
            ? React.ComponentPropsWithRef<E>
            : React.ComponentPropsWithoutRef<E>,
          keyof P
        >
    : P,
  'as'
> & {
  /**
   * Polymorphic prop, can be used to set which component
   * should be used to render
   * @example 'button'
   */
  as?: P extends { as?: string | AnyComponent } ? P['as'] : E;
};

/**
 * This type forms the signature for a forwardRef-enabled component that accepts
 * the "as" prop to dynamically change the underlying rendered JSX. The interface will
 * automatically attempt to extract props from the given rendering target to
 * get proper typing for any specialized props in the target component.
 */
export interface PolymorphicComponent<
  P extends object,
  FallbackComponent extends KnownTarget,
  ForwardRef extends boolean = false
> extends React.ForwardRefExoticComponent<P> {
  <E extends KnownTarget = FallbackComponent>(
    props: PolymorphicComponentProps<E, P, ForwardRef>
  ): React.ReactElement | null;
}

export interface PolymorphicRenderFunction<
  E extends KnownTarget,
  P extends object
> {
  (props: P, Component: E): React.ReactElement | null;
  displayName?: string | undefined;
}

export interface PolymorphicRenderFunctionWithRef<
  E extends KnownTarget,
  P extends object
> {
  (
    props: P,
    Component: E,
    ref: React.ForwardedRef<any>
  ): React.ReactElement | null;
  displayName?: string | undefined;
}

/**
 * Helper function to build polymorphic components (eg. components
 * with an `as` prop that works similarly to that of `styled-components`).
 *
 * @param options options
 * @param render render function
 */
export function polymorphic<
  FallbackComponent extends React.ElementType | AnyComponent,
  P extends object = never,
  ForwardRef extends boolean = false
>(
  options:
    | {
        forwardRef?: ForwardRef;
        element: FallbackComponent extends React.ElementType
          ? FallbackComponent
          : never;
      }
    | {
        forwardRef?: ForwardRef;
        component: FallbackComponent extends AnyComponent
          ? FallbackComponent
          : never;
      },
  render: ForwardRef extends true
    ? PolymorphicRenderFunctionWithRef<FallbackComponent, P>
    : PolymorphicRenderFunction<FallbackComponent, P>
): PolymorphicComponent<
  ForwardRef extends true ? P : Omit<P, 'ref'>,
  FallbackComponent,
  ForwardRef
> {
  if (options.forwardRef) {
    // eslint-disable-next-line react/display-name
    return forwardRef(({ as, ...props }: P & { as?: KnownTarget }, ref) => {
      const Component =
        as ?? ('element' in options ? options.element : options.component);
      return render(props as any, Component as any, ref);
    }) as any;
  } else {
    return (({ as, ...props }: P & { as?: KnownTarget }) => {
      const Component =
        as ?? ('element' in options ? options.element : options.component);
      return (render as PolymorphicRenderFunction<FallbackComponent, P>)(
        props as any,
        Component as any
      );
    }) as any;
  }
}
